import React, { Component } from 'react';
import { StickyContainer, Sticky } from 'react-sticky';
import Gallery from 'react-photo-gallery'

import Menu from './Menu'
import Logo from './Logo'
import Redes from './Redes'
import MenuResponsive from './MenuResponsive'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Proyectos extends Component {
  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    return (
      <div>
        <MenuResponsive />
        <StickyContainer>
          <Sticky>
            {({
              style,
              isSticky,
              wasSticky,
              distanceFromTop,
              distanceFromBottom,
              calculatedHeight
            }) => (
              <header style={style} className="menu_f">
                <Menu />
                <Redes />
              </header>
            )}
          </Sticky>
          <Logo />
          <div className="bg_blanco">
            <div className="max_width padding_width">
              {
                window._my_proyectos.length > 0 ? 
                  <div>
                    <center><h8>••• Proyectos realizados •••</h8></center>
                    <div id="cl"><br/><br/><br/></div>
                    {
                      window._my_proyectos.map((item, i) =>
                        <div key={i}>
                          <h7>{item.nombre}</h7>
                          <br/><br/>
                          <div dangerouslySetInnerHTML={{ __html: item.descripcion }} />
                          <div id="cl"><br/></div>
                          <div data-aos="fade-down">
                            <Gallery photos={item.fotos} />
                          </div>
                          <div id="cl"><br/></div>
                          <div className="linea_proyectos"></div>
                          <div id="cl"><br/></div>
                        </div>
                      )
                    }
                  </div>
                :
                  <center><h8>••• No hay proyectos realizados •••</h8></center>
              }
            </div>
          </div>
        </StickyContainer>
      </div>
    )
  }
}

export default Proyectos;