import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Element } from 'react-scroll';

import Inicio from './components/Inicio'
import Pie from './components/Pie'
import Proyectos from './components/Proyectos'
import About from './components/About'
import Services from './components/Services'
import Contacto from './components/Contacto'

class App extends Component {
  render () {
    return (
      <Router>
	      <Element name="subir"></Element>
	      <Switch>
	        <Route exact path="/" component={Inicio} />
	        <Route path={window.path+"/proyectos"} component={Proyectos} />
	        <Route path={window.path+"/about"} component={About} />
	        <Route path={window.path+"/servicios/:id"} component={Services} />
	        <Route path={window.path+"/contacto"} component={Contacto} />
	        <Route component={Inicio} />
	      </Switch>
	      <div id="cl"></div>
	      <Pie />
      </Router>
    )
  }
}

export default App;
