import React, { Component } from 'react';
import { Link as Ir } from 'react-scroll';

const hoy = new Date(); 
const ano = hoy.getFullYear();

class Pie extends Component {
  render () {
    return (
      <div className="pie">
        <div className="max_width padding_pie">
            <center><h13>Siguenos en redes sociales:</h13></center>
            <div id="subir_sale"><br/></div>
            <Ir to="subir" activeClass="active" spy={true} smooth={true} duration={500} className="subir" title="Subir">▲</Ir>
            <div className="flex_redes">
              {
                window._my_redes.map((item, i) => 
                  <a href={item.link} target="_new" className="de_flex_redes" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
                )
              }
            </div>
            <div className="pie_flex">
              <a href="https://www.adsvega.com/" target="_new" className="grises"><img src={`${window.rutas}img/vega.svg`} alt="ecommerce web design" title="ecommerce web design" className="logo_pie"/></a>
              <div className="de_flex_pie pie_top">
                Todos los derechos reservados © {ano}
              </div>
              {/*
              <div className="de_flex_pie">
                Diseñado por <a href="https://www.firecomm.com.mx/" target="_new" className="grises"><img src={`${window.rutas}img/firecomm.svg`} title="fireComm" alt="fireComm" className="logo_pie"/></a>
              </div>
              */}
            </div>
          </div>
      </div>
    )
  }
}

export default Pie;