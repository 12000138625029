import React, { Component } from 'react';
import { StickyContainer, Sticky } from 'react-sticky';

import Menu from './Menu'
import Logo from './Logo'
import Redes from './Redes'
import MenuResponsive from './MenuResponsive'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import Modal from 'react-bootstrap/Modal';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Services extends Component {
  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)

    var servi = window._my_servicios.filter(p => p.link === this.props.match.params.id);
    if(servi.length===0){
      this.props.history.push('/');
    }
  }

  componentWillUpdate () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  componentDidUpdate () {
    var servi = window._my_servicios.filter(p => p.link === this.props.match.params.id);
    if(servi.length===0){
      this.props.history.push("../"+this.props.match.params.id);
    }
  }

  responsive = {
    0: { items: 1 },
    500: { items: 2 },
    700: { items: 3 },
    900: { items: 4 },
    1100: { items: 5 },
  }

  items = window._my_album
 
  state = {
    show: false,
    categoria: "",
    id: "",

    galleryVestiduras: this.items.filter(p => p.categoria === 'vestiduras').slice(0, 20).map((item, i) => 
      <img data-aos="fade-down" src={`${window.rutas}${item.imagen}`} title={item.titulo} alt={item.titulo} className="carousel_tik" key={i} onClick={this.handleShow.bind(this, item.titulo)}/>
    ),
    galleryInterior: this.items.filter(p => p.categoria === 'interior').slice(0, 20).map((item, i) => 
      <img data-aos="fade-down" src={`${window.rutas}${item.imagen}`} title={item.titulo} alt={item.titulo} className="carousel_tik" key={i} onClick={this.handleShow.bind(this, item.titulo)}/>
    ),
    galleryExterior: this.items.filter(p => p.categoria === 'exterior').slice(0, 20).map((item, i) => 
      <img data-aos="fade-down" src={`${window.rutas}${item.imagen}`} title={item.titulo} alt={item.titulo} className="carousel_tik" key={i} onClick={this.handleShow.bind(this, item.titulo)}/>
    ),
    galleryVolantes: this.items.filter(p => p.categoria === 'volantes').slice(0, 20).map((item, i) => 
      <img data-aos="fade-down" src={`${window.rutas}${item.imagen}`} title={item.titulo} alt={item.titulo} className="carousel_tik" key={i} onClick={this.handleShow.bind(this, item.titulo)}/>
    ),
    galleryOtros: this.items.filter(p => p.categoria === 'otros').slice(0, 20).map((item, i) => 
      <img data-aos="fade-down" src={`${window.rutas}${item.imagen}`} title={item.titulo} alt={item.titulo} className="carousel_tik" key={i} onClick={this.handleShow.bind(this, item.titulo)}/>
    ),
  }

  categoriaShow(e){
    window.H5_loading.show();
    window.H5_loading.hide();
  };

  handleShow(id, e){
    window.H5_loading.show();
    this.setState({ show: true, id: id });
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({ show: false });
  };

  render () {
    return (
      <div>
        <MenuResponsive />
        <StickyContainer>
          <Sticky>
            {({
              style,
              isSticky,
              wasSticky,
              distanceFromTop,
              distanceFromBottom,
              calculatedHeight
            }) => (
              <header style={style} className="menu_f">
                <Menu />
                <Redes />
              </header>
            )}
          </Sticky>
          <Logo />
          <div className="bg_blanco">
            <div className="max_width padding_width">
              {
                window._my_servicios.filter(p => p.link === this.props.match.params.id).map((item, i) =>
                  <div className="pa_flex_2">
                    <div className="de_flex_2">
                      <h9>{item.titulo}</h9>
                      <br/><br/>
                      <div dangerouslySetInnerHTML={{ __html: item.descripcion }} className="at_j black"/>
                    </div>
                    <div className="de_flex_2"><img src={`${window.rutas}${item.imagen}`} className="img_ser" alt={item.titulo} title={item.titulo}/></div>
                  </div>
                )
              }
              <div id="cl"></div>
            </div>
          </div>
          <div className="bg_amarillo">
          {
            window._my_album.filter(p => p.categoria===this.props.match.params.id).length > 0 ?
              <div className="max_width padding_width at_c">
                <h12>GALERÍA</h12>
                <div id="cl"><br/><br/></div>
                <AliceCarousel
                  responsive={this.responsive}
                  autoPlayInterval={3000}
                  autoPlayDirection="rtl"
                  autoPlay={true}
                  fadeOutAnimation={true}
                  mouseTrackingEnabtutoled={false}
                  playButtonEnabled={false}
                  disableAutoPlayOnAction={true}
                  dotsDisabled={false}
                  buttonsDisabled={true}
                  items={
                    this.props.match.params.id === 'vestiduras' ?
                      this.state.galleryVestiduras
                    : this.props.match.params.id === 'interior' ?
                      this.state.galleryInterior
                    : this.props.match.params.id === 'exterior' ?
                      this.state.galleryExterior
                    : this.props.match.params.id === 'volantes' ?
                      this.state.galleryVolantes
                    : this.props.match.params.id === 'otros' ?
                      this.state.galleryOtros
                    :
                      null
                  }
                  ref={(el) => (this.Carousel = el)}
                />
              </div>
            :
              null
          }

          <Modal
            show={this.state.show}
            onHide={this.handleHide.bind(this)}
            dialogClassName="modal-90w"
            className="modal_fer"
            aria-labelledby="example-custom-modal-styling-title">

            <Modal.Body className="modal_cuadro" data-aos="fade-down">
              {
                this.state.id ?
                  window._my_album.filter(p => p.titulo === this.state.id).slice(0, 1).map((item, i) => 
                    <div className="cuadro_modal" style={{backgroundImage:"url('"+window.rutas+item.imagen+"')"}}>
                      <div className="jh-close" onClick={this.handleHide.bind(this)}>x</div>
                    </div>
                  )
                :
                  null
              }
            </Modal.Body>
          </Modal>
        </div>
        </StickyContainer>
      </div>
    )
  }
}

export default Services;