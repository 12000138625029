import React, { Component } from 'react';
import { StickyContainer, Sticky } from 'react-sticky';
import { Link } from 'react-router-dom'

import Menu from './Menu'
import Redes from './Redes'
import MenuResponsive from './MenuResponsive'
import Album from './Album'
import Oferta from './Oferta'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Inicio extends Component {
  componentWillMount () {
    window.H5_loading.show();
    //window.scrollTo(0, 0)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    return (
      <div>
        <div className="inicio_img">
          <div className="back_inicio">
            <center>
              <img src="img/logo_auto.svg" className="logo_inicio" alt="Auto Vestiduras Especiales" title="Auto Vestiduras Especiales"/>
            </center>
          </div>
        </div>
        <MenuResponsive />
        <StickyContainer>
          <Sticky>
            {({
              style,
              isSticky,
              wasSticky,
              distanceFromTop,
              distanceFromBottom,
              calculatedHeight
            }) => (
              <header style={style} className="menu_f">
                <Menu />
                <Redes />
              </header>
            )}
          </Sticky>
          <div className="bg_blanco">
            <div className="max_width padding_width at_c">
              <h8>••• Servicios •••</h8>
              <Oferta />
            </div>
          </div>
          <div className="fondo_2">
            <div className="max_width padding_historia at_c" data-aos="fade-up">
              <img src="img/logo_auto.svg" className="logo_inicio" alt="Auto Vestiduras Especiales" title="Auto Vestiduras Especiales"/>
            </div>
          </div>
          <div className="bg_blanco">
            <div className="max_width padding_width">
              <div className="pa_flex_2 alin">
                <div className="de_flex_2" data-aos="fade-right">
                  <h9>AUTO VESTIDURAS </h9><h10>ESPECIALES</h10>
                  <br/><br/>
                  <div className="at_j ln_h">
                    Auto Vestiduras Especiales es una empresa con {window.cumple} años de experiencia situada en el Estado de México, formada por personal altamente capacitado y experimentado para la elaboración de vestiduras automotrices interiores y exteriores.
                  </div>
                  <div id="cl"><br/></div>
                  <center><Link to="about" className="button">Conoce más de nosotros</Link></center>
                </div>
                <div className="de_flex_2" data-aos="fade-left">
                  <img src="img/negocio.jpg" className="img" alt="Negocio" title="Auto Vestiduras Especiales"/>
                </div>
              </div>
              <div id="cl"><br/></div>
              <div className="pa_flex_2 alin">
                <div className="de_flex_2" data-aos="fade-right">
                  <h9>OTROS </h9><h10>PRODUCTOS</h10>
                  <br/><br/>
                  <div className="at_j ln_h">
                    Fundas para autos, lanchas, motos, sillones, damos mantenimiento a mobiliario de restaurantes bares y discotecas.
                  </div>
                  <div id="cl"><br/></div>
                  <center><Link to="../servicios/otros" className="button">Ver más</Link></center>
                </div>
                <div className="de_flex_2" data-aos="fade-left">
                  <img src="img/otros.jpg" className="img" alt="Otros" title="Otros Productos"/>
                </div>
              </div>
            </div>
          </div>
          <Album />
          {
            window._my_testimonios.length > 0 ?
              <div className="bg_blanco">
                <div className="max_width padding_width at_c">
                  <h8>••• Testimonios •••</h8>
                  <br/><br/>
                  Auto Vestiduras Especiales satisfaciendo a todos nuestros clientes que buscan originalidad en vestiduras automotrices
                  <div id="cl"><br/><br/><br/></div>
                  <div className="pa_flex_4" data-aos="fade-down">
                    {
                      window._my_testimonios.map((item, i) => 
                        <div className="de_flex_4">
                          <img src={item.foto} className="foto_testimonios" title={item.nombre} alt={item.nombre}/>
                          <div id="cl"><br/></div>
                          <h14>{item.nombre}</h14>
                          <br/>
                          <h15>{item.titulo}</h15>
                          <br/>
                          <div className="linea_testimonios"></div>
                          <div className="testimonios">
                            {item.texto}
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            :
              null
          }
        </StickyContainer>
      </div>
    )
  }
}

export default Inicio;