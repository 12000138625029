import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Accordion, AccordionItem } from 'react-sanfona';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class MenuResponsive extends Component {
  constructor() {
    super()
    this.state = {
      verMenu: 'ver_no',
      verCerrar: 'ver_no',
    }
    this.verMenu = this.verMenu.bind(this)
  }

  activa(e) {
    this.setState({verMenu: 'ver_no', verIco: 'menu.svg'})
  }

  verMenu() {
    if(this.state.verMenu==='ver_no'){
      this.setState({verMenu: 'ver_si', verCerrar: 'ver_si_cerrar'})
    }else{
      this.setState({verMenu: 'ver_no', verCerrar: 'ver_no'})
    }
  }

  render () {
    return (
      <div>
        <img src={`${window.rutas}img/menu.svg`}alt="Menú" title="Menú" className="menu_res" height="30" onClick={this.verMenu}/>

        <div className="menu_cerrar" id={this.state.verCerrar} onClick={this.verMenu}></div>
        <div className="menu_r" id={this.state.verMenu}>
          <div className="overflow">
            <h9>AUTO VESTIDURAS </h9><h16>ESPECIALES</h16>
            <div id="cl"><br/></div>
            <ul>
              {
                window._my_menu.map((item, i) =>
                  <li key={i}>
                    {
                      item.tiene === 1 ?
                        <Accordion allowMultiple={false}>
                          <AccordionItem title={item.nombre} className="dt">
                            <div id="cl"><br/></div>
                            {
                              window._my_servicios.map((p, i) =>
                                <div>
                                  <Link to={`../servicios/${p.link}`} className="dd" onClick={this.verMenu}  key={i}>{p.titulo}</Link>
                                  <div id="cl"><br/></div>
                                </div>
                              ) 
                            }
                          </AccordionItem>
                        </Accordion>
                      :
                        <Link to={item.link} className="ir" onClick={this.verMenu}>{item.nombre}</Link>
                    }
                  </li>
                )
              }
            </ul>
            <div id="cl"><br/><br/></div>
          </div>
        </div>
      </div>
    )
  }
}

export default MenuResponsive;