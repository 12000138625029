import React, { Component } from 'react';
import { StickyContainer, Sticky } from 'react-sticky';
import Axios from 'axios';
import Menu from './Menu'
import Logo from './Logo'
import MenuResponsive from './MenuResponsive'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre:'',
      correo:'',
      telefono:'',
      asunto:'',
      mensaje:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  handleClick(e) {
    const {nombre, correo, mensaje, telefono, asunto} = this.state;
    if(nombre===''){
      window.alerta('error','Ingresa tu nombre completo');
    }else if(correo===''){
      window.alerta('error','Ingresa tu correo electrónico');
    }else if(window.validarMail(correo)===false){
      window.alerta('error','Ingrese un correo electrónico valido');
    }else if(mensaje===''){
      window.alerta('error','Escribe un mensaje');
    }else{
      window.H5_loading.show();
      var params = new URLSearchParams();
      params.append('nombre', nombre);
      params.append('correo', correo);
      params.append('mensaje', mensaje);
      params.append('telefono', telefono);
      params.append('asunto', asunto);
      
      Axios.post(`${window.envia}`, params)
        .then(response => {
          if(response.data.elError===1){
            window.alerta('success',response.data.mensaje)
            this.setState({nombre:'',correo:'',mensaje:'',telefono:'',asunto:''});
          }else{
            window.alerta('error',response.data.mensaje)
          }
        })
        .catch(error => {
          //window.alerta('error','LOLERO'+error);
          console.log(error)
        })
        .then(function() {
          window.H5_loading.hide();
        })
      /*
      window.window.H5_loading.show();
      let data = new FormData(document.getElementById('formulario'));
      fetch(window.envia, {
      //fetch('http://localhost/examen/wsCorreoDigital.php', {
         method: 'POST',
         body: data
      })
        .then(function(response) {
          if(response.ok===true){
            window.alerta('success','El mensaje ha sido enviado de manera satisfactoria, pronto nos pondremos en contacto');
          }else{
            window.alerta('error','Ha ocurrido un error intentalo más tarde');
          }
        })
        .then(function(texto) {
           window.H5_loading.hide();
        })
        .catch(err => console.error(err));
        this.setState({nombre:'',correo:'',mensaje:'',telefono:'',asunto:''});
        */
    }
  }

  render () {
    return (
      <div>
        <MenuResponsive />
        <StickyContainer>
          <Sticky>
            {({
              style,
              isSticky,
              wasSticky,
              distanceFromTop,
              distanceFromBottom,
              calculatedHeight
            }) => (
              <header style={style} className="menu_f">
                <Menu />
              </header>
            )}
          </Sticky>
          <Logo />
          <div className="bg_blanco">
            <div className="max_width padding_width_2">
              <center><h8>••• Contactanos •••</h8></center>
              <div id="cl"><br/><br/></div>
              <div className="pa_flex_2">
                <div className="de_flex_2 infcontacto" data-aos="fade-right">
                  <h7>Información de contacto</h7>
                  <ul className="list1">
                    <li className="telefono">{window.telefono}</li>
                    <li className="correo"><a href={`mailto:${window.correo}`}>{window.correo}</a></li>
                    <li className="ubicacion">{window.ubicacion}</li>
                  </ul>
                  <div id="cl"><br/><br/></div>
                  <h13>Siguenos en redes sociales:</h13>
                  <div className="redes_contacto">
                    {
                      window._my_redes.map((item, i) => 
                        <a href={item.link} target="_new" className="de_flex_contacto" key={i}><img src={item.imagen} alt={item.nombre} title={item.nombre}/></a>
                      )
                    }
                  </div>
                </div>
                <div className="de_flex_2" data-aos="fade-left">
                  <form id="formulario">
                    <h7>Deja tu mensaje:</h7>
                    <div id="cl"><br/></div>
                    <input type="text" name="nombre" onChange={this.handleChange} placeholder="Nombre:" value={this.state.nombre}/>
                    <input type="text" name="correo" onChange={this.handleChange} placeholder="Correo:" value={this.state.correo}/>
                    
                    <input type="text" name="telefono" onChange={this.handleChange} placeholder="Teléfono:" value={this.state.telefono}/>
                    <input type="text" name="asunto" onChange={this.handleChange} placeholder="Asunto: ej. Cotización Vestiduras Asientos" value={this.state.asunto}/>

                    <textarea name="mensaje" id="area" onChange={this.handleChange} placeholder="Mensaje:" value={this.state.mensaje}/>                  
                    <center><div onClick={this.handleClick} className="button puntero">ENVIAR</div></center>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <iframe src={window.mapa} className="mapa" title="Mapa" allowfullscreen/>
        </StickyContainer>
      </div>
    )
  }
}

export default Contacto;