import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class Oferta extends Component {
  render () {
    return (
      <div>
        <div id="cl"><br/><br/><br/></div>
        <div className="pa_flex_4">
          {
            window._my_servicios.filter(p => !p.no).map((item, i) => 
              <div className="contiene" data-aos="fade-up" key={i}>
                <Link to={`../servicios/${item.link}`}><img src={item.imagen} alt={item.titulo} title={item.titulo}/></Link>
                <div id="cl"></div>
                <h7>{item.titulo}</h7>
                <br/><br/>
                <div dangerouslySetInnerHTML={{ __html: item.resumen }} />
                <div id="cl"></div>
                <Link to={`../servicios/${item.link}`}><div className="ver_mas">+</div></Link>
              </div>
            )
          }
          {/*
          <div className="contiene" data-aos="fade-right">
            <Link to=""><img src="img/f_1.jpg" alt="Interiores" title="Estética Interior"/></Link>
            <div id="cl"></div>
            <h7>Estética Interior</h7>
            <br/><br/>
            Creamos el diseño de tus sueños, para que tú auto sea unico, solo contactanos  platicanos tus ideas y nosotros nos encargamos de todo.
            <div id="cl"></div>
            <Link to=""><div className="ver_mas">+</div></Link>
          </div>
          <div className="contiene" data-aos="fade-up">
            <Link to=""><img src="img/f_2.jpg" alt="Interiores" title="Estética Interior"/></Link>
            <div id="cl"></div>
            <h7>Estética Exterior</h7>
            <br/><br/>
            Ya no dejes que te suden las manos y tapiza tú volante, al  utilizar piel 100% vacuna en el revestimiento obtienes  un gran confort.
            <div id="cl"></div>
            <Link to=""><div className="ver_mas">+</div></Link>
          </div>
          <div className="contiene" data-aos="fade-left">
            <Link to=""><img src="img/f_3.jpg" alt="Interiores" title="Estética Interior"/></Link>
            <div id="cl"></div>
            <h7>Otros productos</h7>
            <br/><br/>
            Para disfrutar al máximo la conducción de nuestro vehículo los detalles de tapicería tienen que estar perfectos. Nosotros podemos ayudarte en ello.  
            <div id="cl"></div>
            <Link to=""><div className="ver_mas">+</div></Link>
          </div>
          */}
        </div>
        <div id="cl"><br/></div>
      </div>
    )
  }
}

export default Oferta;