import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class Menu extends Component {
  render () {
    return (
      <div className="max_width menu_flex">
        {
          window._my_menu.map((item, i) =>
            <div key={i}>
              {
                item.tiene === 1 ?
                  <div className="dropdown">
                    <div className="ir dropbtn">{item.nombre}</div>
                    <div class="dropdown-content">
                      {
                        window._my_servicios.map((p, i) =>
                          <Link to={`../servicios/${p.link}`} className="m_negro sn_bold" key={i}>{p.titulo}</Link>
                        ) 
                      }
                    </div>
                  </div>
                :
                  <Link to={item.link} className="ir">{item.nombre}</Link>
              }
            </div>
          )
        }
      </div>
    )
  }
}

export default Menu;