import React, { Component } from 'react';
import { StickyContainer, Sticky } from 'react-sticky';
import { Link } from 'react-router-dom'

import Menu from './Menu'
import Logo from './Logo'
import Redes from './Redes'
import MenuResponsive from './MenuResponsive'
import Oferta from './Oferta'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class About extends Component {
  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    return (
      <div>
        <MenuResponsive />
        <StickyContainer>
          <Sticky>
            {({
              style,
              isSticky,
              wasSticky,
              distanceFromTop,
              distanceFromBottom,
              calculatedHeight
            }) => (
              <header style={style} className="menu_f">
                <Menu />
                <Redes />
              </header>
            )}
          </Sticky>
          <Logo />
          <div className="bg_blanco">
            <div className="max_width padding_width">
              <center><h8>••• Sobre Nosotros •••</h8></center>
              <div id="cl"><br/><br/><br/></div>
              <img src="img/equipo.jpg" alt="Equipo" title="Equipo de Trabajo" className="img_libro" data-aos="fade-right"/>
              <div data-aos="fade-left">
                <h9>AUTO VESTIDURAS </h9><h10>ESPECIALES</h10>
                <br/><br/>
                <div className="at_j">
                  Auto Vestiduras Especiales es una empresa con {window.cumple} años de experiencia situada en el Estado de México, formada por personal altamente capacitado y experimentado para la elaboración de vestiduras automotrices interiores y exteriores.
                  <br/><br/>
                  Utilizamos la más alta calidad de materiales para desarrollar diseños originales y personalizados de una forma profesional, siendo una alternativa económica enfocada a personas que rompen con los parámetros comerciales.
                  <br/><br/>
                  ¡Somos especialistas en autos antigüos!
                </div>
              </div>
              <div id="cl"><br/></div>
            </div>
          </div>
          <div className="fondo_3">
            <div className="max_width padding_historia at_c" data-aos="fade-up">
              <h17>¡CONTACTANOS!</h17><br/>
              <h18>Realizar una cotización, solicita más información</h18>
              <div id="cl"><br/><br/></div>
              <center><Link to="contacto"><div className="ver_mas_grande">+</div></Link></center>
            </div>
          </div>

          <div className="bg_amarillo">
            <div className="max_width padding_width at_c">
              <h11>••• Nuesta Oferta •••</h11>
              <Oferta />
            </div>
          </div>
        </StickyContainer>
      </div>
    )
  }
}

export default About;