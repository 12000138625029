import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
//import { Link } from 'react-router-dom'

import Modal from 'react-bootstrap/Modal';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Album extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  responsive = {
    0: { items: 1 },
    500: { items: 2 },
    700: { items: 3 },
    900: { items: 4 },
    1100: { items: 5 },
  }

  items = window._my_album
 
  state = {
    show: false,
    categoria: "",
    id: "",

    gallery: this.items.filter(p => p.destacada>0).slice(0, 17).map((item, i) => 
      <img data-aos="fade-down" src={item.imagen} title={item.titulo} alt={item.titulo} className="carousel_tik" key={i} onClick={this.handleShow.bind(this, item.titulo)}/>
    ),
  }

  categoriaShow(e){
    window.H5_loading.show();
    window.H5_loading.hide();
  };

  handleShow(id, e){
    window.H5_loading.show();
    this.setState({ show: true, id: id });
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({ show: false });
  };

  render () {
      return (
        <div className="bg_amarillo">
          {
            window._my_album.length > 0 ?
              <div className="max_width padding_width at_c">
                <h11>••• Nuestros Proyectos •••</h11>
                <div id="cl"><br/><br/></div>
                <h12>¡creatividad en vestiduras para autos!</h12>
                <br/><br/>
                Aquí encontrarás únicamente originalidad y creatividad, así que si estás buscando algo especial y único, estás en el lugar indicado
                <div id="cl"><br/><br/><br/></div>
                <AliceCarousel
                  responsive={this.responsive}
                  autoPlayInterval={3000}
                  autoPlayDirection="rtl"
                  autoPlay={true}
                  fadeOutAnimation={true}
                  mouseTrackingEnabtutoled={false}
                  playButtonEnabled={false}
                  disableAutoPlayOnAction={true}
                  dotsDisabled={false}
                  buttonsDisabled={true}
                  items={this.state.gallery}
                  ref={(el) => (this.Carousel = el)}
                />
                {/*
                <div id="cl"><br/></div>
                <center><Link to="proyectos" className="button">Ver más proyectos</Link></center>
                */}
              </div>
            :
              null
          }

          <Modal
            show={this.state.show}
            onHide={this.handleHide.bind(this)}
            dialogClassName="modal-90w"
            className="modal_fer"
            aria-labelledby="example-custom-modal-styling-title">

            <Modal.Body className="modal_cuadro" data-aos="fade-down">
              {
                this.state.id ?
                  window._my_album.filter(p => p.titulo === this.state.id).slice(0, 1).map((item, i) => 
                    <div className="cuadro_modal" style={{backgroundImage:"url('"+item.imagen+"')"}}>
                      <div className="jh-close" onClick={this.handleHide.bind(this)}>x</div>
                    </div>
                  )
                :
                  null
              }
            </Modal.Body>
          </Modal>
        </div>
      )
    
  }
}

export default Album;