import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class Logo extends Component {
  render () {
    return (
      <div className="logo_centrar">
        <Link to="inicio">
          <img src={`${window.rutas}img/logo_auto.svg`} className="logo logo_web" alt="Auto Vestiduras Especiales" title="Auto Vestiduras Especiales"/>
        </Link>
      </div>
    )
  }
}

export default Logo;